.MuiDayCalendar-weekContainer button:not(.Mui-disabled),
.MuiPickersYear-root button:not(:disabled) {
  color: #000000de !important;
}

.MuiDayCalendar-weekContainer button[aria-selected='true'],
.MuiPickersYear-root button[aria-checked='true'] {
  color: white !important;
}

.MuiPickersCalendarHeader-label {
  color: #000000de !important;
}
