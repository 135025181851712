body {
  overflow: auto;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #181818 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media screen and (max-width: 992px) {
  body {
    overflow: auto;
  }
}

body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar-track {
  background: #181818;
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar-thumb {
  background: #4a5368;
  border-radius: 4px;
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #4a5368;
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar-thumb:horizontal {
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar-thumb:vertical {
  width: 6px;
  height: 6px;
}
